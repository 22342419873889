/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { createContext, useState, useEffect, useContext } from 'react';
import { getOrganizationBrand } from './utils/CustomAPI';

// Define the shape of the brand settings
interface BrandSettings {
  domainName: string,
  title: string,
  backgroundColor: string,
  textColor: string,
  buttonColor: string,
  brandFavicon: string,
  brandLogo: string,
  sidebarColor: string,
  primaryColor: string,
  secondaryColor: string,
  defaultTextColor: string,
  iconColor: string,
}

// Create context with default value
const BrandContext = createContext<BrandSettings | null>(null);

// Custom hook to use the brand context
export const useBrandSettings = () => useContext(BrandContext);

// BrandProvider component to wrap your app
export const BrandProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [brandSettings, setBrandSettings] = useState<BrandSettings | null>(null);

  useEffect(() => {
    getOrganizationBrand().then((response) => {
      if(response?.data?.configuration.url === 'http://brand.configuration') {
        const brandDetails = JSON.parse(response?.data?.configuration.valueString);
        document.title = brandDetails.title;
        const link = document.querySelector("link[rel*='icon']") as HTMLLinkElement || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = brandDetails.brandFavicon || '../img/favicon.ico';
        document.getElementsByTagName('head')[0].appendChild(link);
        setBrandSettings(JSON.parse(response?.data?.configuration.valueString || '{}'));
        localStorage.setItem('brandDetails', response?.data?.configuration.valueString);
      }
    }).catch((error) => {
      console.log(error);
    });

  }, []);

  // if (!brandSettings) {return <div>Loading...</div>;}

  return (
    <BrandContext.Provider value={brandSettings}>
      {children}
    </BrandContext.Provider>
  );
};
